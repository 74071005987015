import React, { FC, PropsWithChildren, useContext, useMemo } from 'react'

import { ICompany } from '@/features/profile/types'
import { ISafeNoteDraft, ISafeNoteHighlights, ISafeNoteRecipient } from '@/features/safe-note'

interface CreateSafeNoteContextValue {
  data: ISafeNoteDraft
  currentStep: number
  company: ICompany | undefined
  highlights: ISafeNoteHighlights
  updateHighlights: (values: Partial<ISafeNoteHighlights>) => void
  updateData: (values: Partial<ISafeNoteDraft>) => void
  updateDraftRecipient: (values: ISafeNoteRecipient | undefined) => void
  onFinish: () => void
}

const CreateSafeNoteContext = React.createContext<CreateSafeNoteContextValue>(
  {} as CreateSafeNoteContextValue
)

const CreateSafeNoteContextProvider: FC<
  PropsWithChildren<CreateSafeNoteContextValue>
> = (props) => {
  const providerValue = useMemo(() => props, [props])

  return <CreateSafeNoteContext.Provider value={providerValue} {...props} />
}

const useCreateSafeNoteContext = () => useContext(CreateSafeNoteContext)

export { CreateSafeNoteContextProvider, useCreateSafeNoteContext }
