import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { Placement } from 'react-joyride'

import { Color } from '@/packages/palette'
import { FontWeight, Text, TextTypes, Tooltip } from '@/packages/ui'

import styles from './OptionsPopup.module.scss'

export interface IPopupOption {
  id: string
  label: string
  icon?: ReactNode
}

interface OptionsPopupProps {
  options: IPopupOption[]
  visible?: boolean
  placement?: Placement
  onOptionClick: (option: any) => void
  onClickOutside?: () => void
}

const OptionsPopup: FC<OptionsPopupProps & PropsWithChildren> = (props) => {
  const {
    children,
    placement = 'bottom-start',
    options,
    onOptionClick,
    onClickOutside,
    visible
  } = props

  return (
    <Tooltip
      visible={visible}
      trigger="click"
      interactive
      placement={placement}
      onClickOutside={onClickOutside}
      className="!tw-p-0 tw-min-w-[120px] tw-overflow-hidden"
      content={
        <ul className={styles.list}>
          {options.map((option) => (
            <li
              key={option.id}
              onClick={(event) => {
                event.stopPropagation()
                onOptionClick(option.id)
              }}
            >
              {option.icon}
              <Text
                className={styles.optionLabel}
                type={TextTypes.BODY_DEFAULT}
                weight={FontWeight.SEMIBOLD}
                color={Color.neutral400}
              >
                {option.label}
              </Text>
            </li>
          ))}
        </ul>
      }
    >
      <div onClick={(event) => event.stopPropagation()}>{children}</div>
    </Tooltip>
  )
}

export default OptionsPopup
