import React, { FC } from 'react'

import { SharedImages } from '@/assets/images'
import { Avatar, ConditionalRender } from '@/common/components'
import { DateHelper } from '@/common/helpers'
import { StoreType, withStore } from '@/common/store'
import { EditDraftRecipientModal } from '@/features/dashboard/modals'
import { ISafeNoteRecipient, SafeNoteStatus } from '@/features/safe-note'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  FontWeight,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './SAFE.module.scss'

// import requestTo from '@/features/dashboard/assets/images/safe-request-to.svg'

interface SafeProps {
  row: any
  withDate?: boolean
  withEmail?: boolean
  type?: 'term-sheet' | 'safe-note'
}

const mapStateToProps = ({ safeNote, user }: StoreType) => ({
  updateDraftRecipient: safeNote.updateDraftRecipient,
  updateLoading: safeNote.loading,
  userPermissions: user.companiesPermission
})

const SAFE: FC<SafeProps & ReturnType<typeof mapStateToProps>> = (props) => {
  const {
    withDate,
    withEmail,
    type = 'safe-note',
    row: { sender, recipient, senderCompanyId, createdAt, data, status, id: safeId },
    updateDraftRecipient,
    updateLoading,
    userPermissions,
  } = props

  const draft = status === SafeNoteStatus.DRAFT

  const editDraftRecipientModal = useModal(false)

  const handleDraftRecipientEdit = async ({ email, name }: {email: string, name: string}) => {
    await updateDraftRecipient({ data: { payload: { email, fullName: name, userId: recipient.id, safeId } } });

    editDraftRecipientModal.closeModal()
  }

  const user = (() => {
    if (type === 'term-sheet') {
      return sender
    }

    if (draft && data?.recipients?.length === 1) {
      return data.recipients[0]
    }

    return recipient
  })()

  const isUserActive = 'active' in (user || {}) ? user?.active : true

  const permission = userPermissions[senderCompanyId]

  const renderDetails = (name: string | undefined, email: string, active?: boolean) => (
    <Col gap={0}>
      <ConditionalRender condition={editDraftRecipientModal.visible}>
        <EditDraftRecipientModal
          recipient={recipient}
          closeModal={editDraftRecipientModal.closeModal}
          loading={updateLoading}
          companyId={senderCompanyId}
          onSave={handleDraftRecipientEdit}
        />
      </ConditionalRender>

      <Row items="center" gap={3}>
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.neutral500}
          weight={FontWeight.SEMIBOLD}
        >
          {name}
        </Text>

        <ConditionalRender condition={!active}>
          <Badge size="small" type={BadgeType.DEFAULT}>
            Not registered
          </Badge>
        </ConditionalRender>
      </Row>

      <Row items="baseline" gap={10}>
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.neutral500}
        >
          {email}
        </Text>

        <ConditionalRender condition={!active && permission === 'create'}>
          <Row items="baseline" gap={2} clickable onClick={() => editDraftRecipientModal.openModal()}>
            <SharedIcons.Pencil size={12} color={Color.primary500} />
            <Text
              type={TextTypes.BODY_SMALL}
              color={Color.primary400}
            >
              Edit
            </Text>
          </Row>

        </ConditionalRender>
      </Row>

      <ConditionalRender condition={withDate}>
        <Text type={TextTypes.BODY_EXTRA_SMALL} color={Color.neutral400}>
          {DateHelper.toPrettyFormat(createdAt)}
        </Text>
      </ConditionalRender>
    </Col>
  )

  const renderDetailsMinimal = (name: string | undefined) => (
    <Col gap={0}>
      <ConditionalRender condition={editDraftRecipientModal.visible}>
        <EditDraftRecipientModal
          recipient={recipient}
          closeModal={editDraftRecipientModal.closeModal}
          loading={updateLoading}
          companyId={senderCompanyId}
          onSave={handleDraftRecipientEdit}
        />
      </ConditionalRender>

      <Row items="center" gap={3}>
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.neutral500}
          weight={FontWeight.SEMIBOLD}
        >
          {name}
        </Text>
      </Row>

    </Col>
  )

  return (
    <Row gap={withDate ? 10 : 16} items="center">
      {draft && !!data?.recipients?.length ? (
        <div className={styles.multiRecipients}>
          {data.recipients.map((item: ISafeNoteRecipient) => (
            <Avatar
              key={item.email}
              size={36}
              name={item?.name}
              image={item?.img}
              defaultImage={SharedImages.UserPlaceholder}
            />
          ))}
        </div>
      ) : (
        <Avatar size={36} name={user?.fullName} image={user?.image} />
      )}

      {(!draft || data?.recipients?.length === 1) &&
        <ConditionalRender condition={withEmail} fallbackElement={renderDetailsMinimal(user?.fullName || user?.name)}>
          {renderDetails(user?.fullName || user?.name, user.email, isUserActive)}
        </ConditionalRender>
        }
    </Row>
  )
}

export default withStore(mapStateToProps)(SAFE)
