import {
  SAFE_NOTE_TEMPLATE_ID,
  SafeNoteTemplateStyles
} from '@/features/safe-note'

export function getRawSafeTemplate() {
  const doc = document.getElementById(SAFE_NOTE_TEMPLATE_ID)

  const clonedDoc = doc?.cloneNode(true) as HTMLElement;

  clonedDoc?.querySelectorAll('mark[data-markjs=\'true\']').forEach((markEl) => {
    if(!markEl.textContent || !markEl.parentNode) return

    const textNode = document.createTextNode(markEl.textContent);

    markEl.parentNode.replaceChild(textNode, markEl);
  });

  const html = clonedDoc?.outerHTML


  const styles = SafeNoteTemplateStyles

  return `
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta
        name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
      >
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <title>SAFE Note</title>
      <style>${styles}</style>
    </head>
    <body>
      ${html}
    </body>
    </html>
  `
}
