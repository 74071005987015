import { SignatureData } from '@/features/safe-note/modals'

export enum Terms {
  MFN = 'mfn',
  HELP_SET_TERMS = 'helpSetTerms'
}

export enum ViewOptionTypes {
  DELETE_SAFE = 'deleteSafe',
  SEND_REMINDER = 'sendReminder',
}

export interface ViewOption {
  id: ViewOptionTypes,
  label: string
}

export enum LearnMoreModals {
  MFN = 'mfnInfo',
  HELP_SET_TERMS = 'termsInfo'
}

export interface ISafeNoteRecipient {
  name: string
  email: string
  amount: number
  img?: string
}

export interface ISafeNoteSignatures {
  senderSignature: SignatureData | undefined
  senderSignatureName: string | undefined
  senderSignatureDate: Date | undefined

  recipientSignature: SignatureData | undefined
  recipientSignatureName: string | undefined
  recipientSignatureDate: Date | undefined
}

export interface ISafeNoteDraft {
  id?: string
  recipients: ISafeNoteRecipient[]

  selectedTermsId: Terms | undefined
  mfn: boolean
  discountRate: number | undefined
  discountRateActive: boolean
  valuationCap: number | undefined
  valuationCapActive: boolean

  signatures: ISafeNoteSignatures
}

export interface ISafeNoteHighlights {
  mfn: boolean
  discountRate: boolean
  valuationCap: boolean
  helpSetTerms: boolean
}
