import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ConditionalRender, Search } from '@/common/components'
import companiesSorting from '@/common/constants/companiesSorting'
import { StoreType, withStore } from '@/common/store'
import {
  CompaniesList,
  DashboardEmptyScreen,
  InvitesBlock
} from '@/features/dashboard'
import { TeamIcon } from '@/features/dashboard/assets'
import { useDashboardNavigationContext } from '@/features/dashboard/context'
import {
  AddNewProfileModal,
  IncomingSafesModal,
  InvitationsModal
} from '@/features/dashboard/modals'
import { ICompanySummary } from '@/features/profile/api/types'
import { ICompany } from '@/features/profile/types'
import { ISafeNote } from '@/features/safe-note'
import { IncomingTermSheetsModal } from '@/features/term-sheet/components'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  BackButton,
  Col,
  Heading,
  HeadingTypes,
  Loader,
  Row,
  Select
} from '@/packages/ui'

import IncomingSafeIcons from '../../assets/icons/IncomingSafeIcons'
import styles from './DashboardCompaniesLevelView.module.scss'

const mapStateToProps = ({
  user,
  safeNote,
  profile,
  termSheet
}: StoreType) => ({
  companiesList: user.sortedCompanies,
  myId: user.me?.id,
  fetchMultipleSummaries: profile.fetchSummaries,
  refetchCompany: user.refetchCompany,
  fetchPendingSafes: safeNote.fetchPendingSafes,
  pendingTermSheets: termSheet.pendingTermSheets,
  fetchPendingTermSheets: termSheet.fetchPendingTermSheets,
  loading:
    user.functionLoading.fetchMe ||
    user.functionLoading.fetchPendingSafes ||
    user.functionLoading.fetchPendingTermSheets ||
    user.functionLoading.restoreCompany
})

type CompaniesLevelViewProps = ReturnType<typeof mapStateToProps>

interface CompanyListProps {
  sort: string;
  selectedProfile: ProfileTypes;
  counter?: number;
}

const CompanyHeading = ({ sort, selectedProfile, counter = 0 }: CompanyListProps) => {
  let headingText;

  if (sort === 'deleted') {
    headingText = 'Deleted Companies'
  } else if (selectedProfile === ProfileTypes.ANGEL) {
    headingText = 'Investment Companies';
  } else {
    headingText = 'Companies';
  }

  return (
    <Heading type={HeadingTypes.H2} color={Color.neutral500}>
      {headingText} ({counter})
    </Heading>
  );
};

const DashboardCompaniesLevelView: FC<CompaniesLevelViewProps> = (props) => {
  const {
    refetchCompany,
    fetchMultipleSummaries,
    companiesList,
    myId,
    loading,
    fetchPendingSafes,
    pendingTermSheets,
    fetchPendingTermSheets
  } = props

  const [summaries, setSummaries] = useState<ICompanySummary[]>([])
  const [pendingSafes, setPendingSafes] = React.useState<ISafeNote[]>([])
  const { selectedProfile = ProfileTypes.ENTREPRENEUR } =
    useDashboardNavigationContext()

  const { visible, closeModal, openModal } = useModal<
    'invites' | 'pendingSafes' | 'addProfile' | 'termSheets'
  >()

  const [search, setSearch] = React.useState<string>()
  const [sort, setSort] = React.useState<string>(companiesSorting[0].value)

  const profileCompanies = companiesList[selectedProfile]
  const deletedCompanies = companiesList.deleted[selectedProfile]
  const [searchParams] = useSearchParams()

  const requests = companiesList.requests.filter(
    (company) => company.type === selectedProfile
  )

  const sortOptions = useMemo(() => {
    const items = [...companiesSorting]

    if (deletedCompanies.length) {
      items.push({ value: 'deleted', label: 'Deleted companies' })
    }

    return items
  }, [deletedCompanies])

  const noPendingSafes =
    selectedProfile === ProfileTypes.ENTREPRENEUR ? true : !pendingSafes.length

  const noPendingTermSheets =
    selectedProfile === ProfileTypes.ENTREPRENEUR
      ? true
      : !pendingTermSheets.length

  const noCompanies =
    !profileCompanies.length && !requests.length && !deletedCompanies.length

  const companies: ICompany[] = useMemo(() => {
    const sortedCompanies: ICompany[] =
      sort === 'deleted'
        ? deletedCompanies
        : profileCompanies.filter((company: ICompany) => {
            const isMyCompany = company.owner.id === myId

            if (sort === 'shared') return !isMyCompany
            if (sort === 'mine') return isMyCompany

            return true
          })

    const filteredCompanies = !!search
      ? sortedCompanies.filter((company) =>
          company.name.toLowerCase().includes(search.toLowerCase())
        )
      : sortedCompanies

    return filteredCompanies
  }, [deletedCompanies, profileCompanies, search, sort, myId])

  const _fetchPendingSafes = async () => {
    try {
      const result = await fetchPendingSafes()

      if (!!result) {
        setPendingSafes(result)
      } else {
        setPendingSafes([])
      }
    } catch {
      setPendingSafes([])
    }
  }

  const fetchSummaries = async () => {
    if (selectedProfile === ProfileTypes.ADVISOR) return

    try {
      const result = await fetchMultipleSummaries({
        data: { type: selectedProfile }
      })

      if (Array.isArray(result)) {
        setSummaries(result)
        return
      }

      setSummaries([])
    } catch {
      setSummaries([])
    }
  }

  useEffect(() => {
    if (selectedProfile === ProfileTypes.ANGEL) {
      _fetchPendingSafes()
      fetchPendingTermSheets()
    }

    fetchSummaries()
  }, [selectedProfile])

  useEffect(() => {
    if (sort === 'deleted' && !deletedCompanies.length) {
      setSort('all')
    }
  }, [deletedCompanies, sort])

  useEffect(() => {
    if (pendingSafes.length && searchParams.get('showPendingSafes')) {
      openModal('pendingSafes')
      searchParams.delete('showPendingSafes')
      window.history.replaceState(null, '', window.location.pathname)
    }
  }, [pendingSafes])

  if (loading) {
    return (
      <Row
        items="center"
        justify="center"
        className="tw-self-stretch tw-w-full"
      >
        <Loader width="100%" height="100%" />
      </Row>
    )
  }

  const filterOutPendingSafes = (id: string, companyId?: string) => {
    setPendingSafes((prev) => prev.filter((item) => item.id !== id))

    if (companyId) {
      refetchCompany({ data: { id: companyId } })
    }
  }

  const closeSafesModal = () => {
    closeModal()
    fetchSummaries()
  }

  const closeInvitesModal = (refetch?: boolean) => {
    closeModal()

    if (refetch) {
      fetchSummaries()
    }
  }

  if (noCompanies && noPendingSafes && noPendingTermSheets) {
    return <DashboardEmptyScreen key={selectedProfile} type={selectedProfile} />
  }

  return (
    <Col gap={24} className={styles.wrapper}>
      {visible === 'invites' && (
        <InvitationsModal
          companies={requests}
          myId={myId}
          closeModal={closeInvitesModal}
        />
      )}

      {visible === 'pendingSafes' && (
        <IncomingSafesModal
          safes={pendingSafes}
          myId={myId}
          filterOutPendingSafes={filterOutPendingSafes}
          closeModal={closeSafesModal}
          toCreateProfile={() => openModal('addProfile')}
          availableCompanies={profileCompanies}
        />
      )}

      {visible === 'termSheets' && (
        <IncomingTermSheetsModal
          closeModal={closeModal}
          termSheets={pendingTermSheets}
        />
      )}

      {visible === 'addProfile' && (
        <AddNewProfileModal
          predefinedRole={selectedProfile}
          closeModal={closeModal}
        />
      )}

      <Row items="center" justify="between" gap={20}>
        <Row items="center" gap={15}>
          <Col gap={4}>
            {sort === 'deleted' && (
              <BackButton onClick={() => setSort('all')}>
                Back to all
              </BackButton>
            )}

            <CompanyHeading sort={sort} selectedProfile={selectedProfile} counter={companies?.length} />
          </Col>

          {sort !== 'deleted' && (
            <>
              <InvitesBlock
                condition={!!requests.length}
                onClick={() => openModal('invites')}
                title="Invitations"
                count={requests?.length || 0}
                icon={TeamIcon}
              />

              <InvitesBlock
                condition={
                  !!pendingSafes.length &&
                  selectedProfile === ProfileTypes.ANGEL
                }
                onClick={() => openModal('pendingSafes')}
                title="Incoming SAFEs"
                count={pendingSafes?.length || 0}
                icon={IncomingSafeIcons.IncomingSafe}
              />

              <InvitesBlock
                condition={
                  !!pendingTermSheets.length &&
                  selectedProfile === ProfileTypes.ANGEL
                }
                onClick={() => openModal('termSheets')}
                title="Term sheets"
                count={pendingTermSheets?.length || 0}
                icon={IncomingSafeIcons.IncomingSafe}
              />
            </>
          )}
        </Row>

        <Row items="stretch" gap={14}>
          <Search
            search={search}
            onSearchChange={(e) => setSearch(e.target.value)}
          />
          <Select
            small
            highlighted={sort !== companiesSorting[0].value}
            name="sort"
            placeholder="Sort By"
            value={sort}
            options={sortOptions}
            onChange={(value: string) => setSort(value)}
          />
        </Row>
      </Row>

      <ConditionalRender
        condition={['all', 'mine'].includes(sort) && !profileCompanies.length}
        fallbackElement={
          <CompaniesList
            deleted={sort === 'deleted'}
            search={search}
            summaries={summaries}
            type={selectedProfile}
            companies={companies}
            sharedAmount={companies.length}
            sharedOpened={sort === 'shared'}
          />
        }
      >
        <DashboardEmptyScreen type={selectedProfile} />
      </ConditionalRender>
    </Col>
  )
}

export default withStore(mapStateToProps)(DashboardCompaniesLevelView)
