import { RecipientStep, ReviewStep, TermsStep } from '@/features/safe-note'
import { useCreateSafeNoteContext } from '@/features/safe-note/context/CreateSafeNoteContext'

const CreateSafeNoteView = () => {
  const { currentStep } = useCreateSafeNoteContext()

  const ComponentMapper = [
    <RecipientStep key="recipientStep" />,
    <TermsStep key="termsStep" />,
    <ReviewStep key="reviewStep" />
  ]

  return ComponentMapper[currentStep]
}

export default CreateSafeNoteView
