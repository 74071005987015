import 'react-input-range/lib/css/index.css'
import './Slider.scss'

import React, { FC, useCallback, useMemo } from 'react'
import InputRange from 'react-input-range'
import { NumberFormatValues } from 'react-number-format'

import { ModalContentBox, ModalFooter } from '@/common/components'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { useSmartState } from '@/packages/hooks'
import { IModalWithCloseFn, Modal, NumberInput, Row } from '@/packages/ui'

import { SAFE_NOTE_WRAPPER_ID } from '../../constants'
import styles from './SetTermsModal.module.scss'

interface SetTermsModalProps extends IModalWithCloseFn {
  valuationCap?: number
  discountRate?: number
  onSelect?: (values: any) => void
  type: 'discount' | 'valuation'
}

const SetTermsModal: FC<SetTermsModalProps> = ({
  type,
  closeModal,
  onSelect,
  valuationCap,
  discountRate
}: SetTermsModalProps) => {
  const [state, setState] = useSmartState<any>({
    valuationCap,
    discountRate
  })

  const handleSetTerms = useCallback(() => {
    if (type === 'discount') {
      onSelect?.({
        discountRate: state.discountRate,
        discountRateActive: true
      })
    } else {
      onSelect?.({
        valuationCap: state.valuationCap,
        valuationCapActive: true
      })
    }

    closeModal?.()
  }, [state])

  const disabledButtonCondition = useMemo(
    () =>
      (type === 'valuation' &&
        (!state.valuationCap || state.valuationCap === valuationCap)) ||
      (type === 'discount' &&
        (!state.discountRate || state.discountRate === discountRate)),
    [state]
  )

  const DiscountRateForm = (
    <>
      <div className={styles.discountRateBox}>
        <NumberInput
          suffix="%"
          autoFocus
          decimalScale={2}
          className={styles.discountRateInput}
          placeholder="%"
          value={state.discountRate || 0}
          isAllowed={({ floatValue }: NumberFormatValues) =>
            floatValue ? floatValue <= 99 : true
          }
          onValueChange={(values: NumberFormatValues) =>
            setState({ discountRate: values.floatValue })
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !disabledButtonCondition) {
              handleSetTerms()
            }
          }}
        />

        <div className={styles.rangeInput}>
          <InputRange
            maxValue={99}
            step={1}
            minValue={0}
            value={state.discountRate || 0}
            onChange={(value) => setState({ discountRate: value })}
          />
        </div>
      </div>
    </>
  )

  const ValuationCapForm = (
    <>
      <Row items="center" gap={18} className="tw-self-stretch">
        <NumberInput
          autoFocus
          label="Valuation cap"
          placeholder="Cap"
          prefix="$"
          value={state.valuationCap}
          onValueChange={(values: NumberFormatValues) =>
            setState({ valuationCap: values.floatValue })
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !disabledButtonCondition) {
              handleSetTerms()
            }
          }}
        />
      </Row>
    </>
  )

  return (
    <Modal id={SAFE_NOTE_WRAPPER_ID} visible setVisible={closeModal} size="sm">
      <ModalContentBox
        name={type}
        title={type === 'discount' ? 'Add discount' : 'Add valuation cap'}
        description={
          type === 'discount'
            ? 'Set a discount rate for the SAFE.'
            : ' Set a valuation cap for the SAFE.'
        }
        img={
          type === 'discount'
            ? SafeNoteImages.DiscountBig
            : SafeNoteImages.ValuationBig
        }
        imgSize={130}
      >
        {type === 'discount' ? DiscountRateForm : ValuationCapForm}

        <ModalFooter
          leftButtonText="Cancel"
          rightButtonText="Save"
          leftButtonAction={closeModal}
          rightButtonActon={handleSetTerms}
          disabled={disabledButtonCondition}
        />
      </ModalContentBox>
    </Modal>
  )
}

export default SetTermsModal
