import React, { useMemo } from 'react'

import { ModalFooter } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import { CompanyOption } from '@/features/dashboard/modals/IncomingSafesModal/parts/SafesList'
import { ICompany } from '@/features/profile/types'
import { ISafeNote } from '@/features/safe-note'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Col,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Select,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

const mapStateToProps = ({ user, safeNote }: StoreType) => ({
  companiesList: user.sortedCompanies,
  permissionsList: user.companiesPermission,
  acceptSafeNote: safeNote.acceptSafeNote,
  loading: safeNote.functionLoading.acceptSafeNote
})

type AcceptSafeModalProps = IModalWithCloseFn &
  ReturnType<typeof mapStateToProps> & {
    safeNote: ISafeNote
    toCreateCompany?: () => void
    onAccepted?: () => void
  }

const AcceptSafeModal = ({
  closeModal,
  toCreateCompany,
  companiesList,
  permissionsList,
  safeNote,
  acceptSafeNote,
  loading,
  onAccepted
}: AcceptSafeModalProps) => {
  const availableCompanies = companiesList[ProfileTypes.ANGEL]

  const [selectedCompanyId, setSelectedCompanyId] = React.useState<string>()

  const options = useMemo(() => {
    let list: any[] = []

    if (availableCompanies?.length) {
      list = availableCompanies?.map((company: ICompany) => {
        const permission = permissionsList[company.id];

        return {
          value: company.id,
          label: company.name,
          image: company.image || '',
          disabled: permission !== TeamMemberPermission.CREATE
        }
      })
    }

    list.push({
      value: 'addCompany',
      label: 'Create a New Investment Company',
      image: ''
    })

    return list
  }, [availableCompanies])

  const handleDecline = () => {
    closeModal?.()
    ToastService.showSuccess('SAFE was declined')
  }

  const handleAccept = async () => {
    if (!selectedCompanyId) {
      ToastService.showWarning('Select the company first!')
      return
    }

    if (!safeNote) {
      ToastService.showWarning('Received invalid SAFE note!')
      return
    }

    await acceptSafeNote({
      data: { id: safeNote.id, companyId: selectedCompanyId! },
      options: {
        onSuccess: () => {
          onAccepted?.()
          closeModal?.()
          ToastService.showSuccess(
            `SAFE from ${safeNote.sender.fullName} was accepted`
          )
        }
      }
    })
  }

  return (
    <Modal size="sm" id="accept-safe-model" visible setVisible={closeModal}>
      <Col items="center">
        <img src={SafeNoteImages.AcceptSafe} alt="accept-safe" width={107} />

        <Heading
          type={HeadingTypes.H2}
          className="tw-w-8/12"
          align={TextAlign.CENTER}
        >
          Which of your Angel companies is accepting this SAFE?
        </Heading>

        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral400}
          className="tw-mt-[16px]"
        >
          Сhoose in which investment company you want to display SAFE
        </Text>

        <Select
          custom
          withPortal
          CustomOption={CompanyOption}
          className="tw-mt-[10px]"
          placeholder="Write or select the company"
          name="companies"
          options={options}
          value={selectedCompanyId}
          onChange={(val: string) => {
            if (val === 'addCompany') {
              setSelectedCompanyId(undefined)
              toCreateCompany?.()
            }

            setSelectedCompanyId(val)
          }}
        />
      </Col>

      <ModalFooter
        className="tw-mt-16"
        leftButtonText="Decline"
        leftButtonAction={handleDecline}
        rightButtonText={loading ? 'Loading...' : 'Accept'}
        rightButtonActon={handleAccept}
        disabled={loading || !selectedCompanyId}
      />
    </Modal>
  )
}

export default withStore(mapStateToProps)(AcceptSafeModal)
