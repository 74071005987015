import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ConditionalRender, UploadImage } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import {
  ChangePasswordSchema,
  EditUserSchema
} from '@/features/auth/schemas/AuthSchema'
import { Color } from '@/packages/palette'
import {
  Button,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  IFile,
  PasswordInput,
  Row,
  Text,
  TextInput,
  TextTypes
} from '@/packages/ui'

const mapStateToProps = ({ user }: StoreType) => ({
  me: user.me,
  updateMe: user.updateCurrentUser,
  loading: user.functionLoading.updateUser
})

const ProfileSettingsView: FC<ReturnType<typeof mapStateToProps>> = (props) => {
  const { me, updateMe, loading } = props

  const [photo, setPhoto] = useState<string | IFile | undefined>(undefined)

  const isOauthUser = !!me?.oauthProviders?.length

  const {
    getValues,
    setValue,
    register,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(EditUserSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const {
    getValues: getPasswordValues,
    register: registerPassword,
    reset: resetPassword,
    formState: { errors: passwordErrors, isValid: passwordIsValid }
  } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const handleUserUpdate = async () => {
    const { fullName, email } = getValues()

    if (!fullName || !email) return

    await updateMe({
      data: {
        payload: {
          fullName: String(fullName),
          image: photo,
          ...(!isOauthUser && { email: String(email)})
        }
      }
    })
  }

  const handlePasswordUpdate = async () => {
    const { password, newPassword } = getPasswordValues()

    if (!password || !newPassword) return

    await updateMe({
      data: {
        payload: { newPassword, oldPassword: password }
      },
      options: {
        onSuccess: () => {
          ToastService.showSuccess('Your password was changed successfully');
          resetPassword();
        }
      }
    })
  }

  useEffect(() => {
    if (me?.image) {
      setPhoto(me?.image)
    } else {
      setPhoto(undefined)
    }

    if (me?.id) {
      setValue('fullName', me?.fullName)
      setValue('email', me?.email)
    }
  }, [me])

  return (
    <div className="tw-mb-50">
      <Heading type={HeadingTypes.H2} color={Color.neutral500}>
        My profile
      </Heading>

      <Text
        type={TextTypes.BODY_DEFAULT}
        color={Color.neutral400}
        className="tw-mt-8"
      >
        Manage your profile here. Upload a new photo, update your email, or
        change your password.
      </Text>

      <div className={clsx('tw-mt-32', !photo && 'tw-mb-20')}>
        <UploadImage
          description="Pick a photo to showcase on your profile. You can always change this later in settings. "
          image={photo}
          label="Profile photo"
          caption="Upload photo (PNG or JPG)"
          onImageSelect={setPhoto}
        />
      </div>

      <Form onSubmit={handleUserUpdate}>
        <Row items="center" gap={24}>
          <FormItem errors={errors.fullName?.message}>
            <TextInput
              {...register('fullName')}
              name="fullName"
              label="Your full name"
              placeholder="Full Name"
              invalid={FormHelper.isFieldInvalid('fullName', errors)}
            />
          </FormItem>
          <FormItem errors={errors.email?.message}>
            <TextInput
              {...register('email')}
              name="email"
              label="Your email"
              placeholder="Email"
              disabled={isOauthUser}
              invalid={FormHelper.isFieldInvalid('email', errors)}
            />
          </FormItem>
        </Row>

        <Button
          onClick={handleUserUpdate}
          disabled={!isValid || loading}
          loading={loading}
          uppercase
          width="default"
        >
          Save
        </Button>
      </Form>

      <ConditionalRender condition={!isOauthUser}>
        <div className="tw-mt-50">
          <Form>
            <Row items="center" gap={24}>
              <FormItem errors={passwordErrors.password?.message}>
                <PasswordInput
                  {...registerPassword('password')}
                  name="password"
                  label="Old password"
                  placeholder="Old password"
                  invalid={FormHelper.isFieldInvalid(
                    'password',
                    passwordErrors
                  )}
                />
              </FormItem>
              <FormItem errors={passwordErrors.newPassword?.message}>
                <PasswordInput
                  {...registerPassword('newPassword')}
                  name="newPassword"
                  label="New password"
                  placeholder="New password"
                  invalid={FormHelper.isFieldInvalid(
                    'newPassword',
                    passwordErrors
                  )}
                />
              </FormItem>
            </Row>

            <Button
              onClick={handlePasswordUpdate}
              disabled={!passwordIsValid || loading}
              loading={loading}
              uppercase
              width="default"
            >
              Change password
            </Button>
          </Form>
        </div>
      </ConditionalRender>
    </div>
  )
}

export default withStore(mapStateToProps)(ProfileSettingsView)
