import React from 'react'
import { useForm } from 'react-hook-form'

import { ConditionalRender, ModalFooter } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { VerificationMark } from '@/features/auth/assets/icons'
import { IncomingSafeIcons } from '@/features/dashboard/assets'
import { ISafeNote, SafeNoteStatus } from '@/features/safe-note'
import { SafeNoteImages } from '@/features/safe-note/assets'
import { VerificationStatus } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  FontWeight,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Row,
  Text,
  TextAlign,
  TextArea,
  TextTypes
} from '@/packages/ui'

import styles from './DeleteSafeModal.module.scss'

type DeleteSafeModalProps = {
  closeModal: IModalWithCloseFn['closeModal']
  safeNote: ISafeNote
  loading?: boolean
  onDelete: (safeNoteId: string, message: string) => void
}

// TODO: this component reuse some logic from
//  src/features/dashboard/modals/IncomingSafesModal/parts/SafesList.tsx
//  please later on consider moving  reusable to a separate component
const DeleteSafeModal = ({
  safeNote,
  loading,
  closeModal,
  onDelete
}: DeleteSafeModalProps) => {
  const {
    valuationCap: valuation,
    discountRate: discount,
    senderCompany,
    safeAmount: amount,
    mfn
  } = safeNote

  const { register, getValues } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const CompanyRow = (
    <Row
      items="center"
      justify="between"
      gap={15}
      className={styles.safeInfoItem}
    >
      <Row items="center" gap={6}>
        <IncomingSafeIcons.Company />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          Company:
        </Text>
      </Row>

      <Row items="center" gap={7}>
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral500}
        >
          {senderCompany.name}
        </Text>

        <ConditionalRender
          condition={
            senderCompany.verificationStatus === VerificationStatus.Verified
          }
        >
          <VerificationMark color={Color.primary400} />
        </ConditionalRender>
      </Row>
    </Row>
  )

  const MFN = (
    <Row
      items="center"
      justify="between"
      gap={15}
      className={styles.safeInfoItem}
    >
      <Row items="center" gap={6}>
        <IncomingSafeIcons.MFN />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          Terms:
        </Text>
      </Row>

      <Badge type={BadgeType.INFO}>MFN</Badge>
    </Row>
  )

  const Amount = (
    <Row
      items="center"
      justify="between"
      gap={15}
      className={styles.safeInfoItem}
    >
      <Row items="center" gap={6}>
        <IncomingSafeIcons.Amount />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          Amount
        </Text>
      </Row>

      <Badge type={BadgeType.INFO}>{toCurrencyFormat(amount || 0, '$')}</Badge>
    </Row>
  )

  const Discount = (
    <Row
      items="center"
      justify="between"
      gap={15}
      className={styles.safeInfoItem}
    >
      <Row items="center" gap={6}>
        <IncomingSafeIcons.DiscountRate />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          Discount rate:
        </Text>
      </Row>

      <Badge type={BadgeType.SUCCESS}>{discount}%</Badge>
    </Row>
  )

  const Valuation = (
    <Row
      items="center"
      justify="between"
      gap={15}
      className={styles.safeInfoItem}
    >
      <Row items="center" gap={6}>
        <IncomingSafeIcons.ValuationCap />
        <Text
          type={TextTypes.BODY_DEFAULT}
          weight={FontWeight.MEDIUM}
          color={Color.neutral400}
        >
          Valuation cap:
        </Text>
      </Row>

      <Badge type={BadgeType.SUCCESS}>
        {toCurrencyFormat(valuation || 0, '$')}
      </Badge>
    </Row>
  )

  return (
    <Modal id="delete-safe-note" visible setVisible={closeModal} size="sm">
      <div className={styles.safeInfo}>
        <div className={styles.safeInfoContent}>
          <div className={styles.imageContainer}>
            <img src={SafeNoteImages.ErrorWarning} alt="warning" />
          </div>

          <Heading
            type={HeadingTypes.H3}
            align={TextAlign.CENTER}
            className="tw-mt-12"
          >
            You’re about to delete this SAFE note
          </Heading>

          <ConditionalRender
            condition={safeNote.status === SafeNoteStatus.DRAFT}
            fallbackElement={
              <Text
                type={TextTypes.BODY_DEFAULT}
                color={Color.neutral500}
                align={TextAlign.CENTER}
                className="tw-mt-8"
              >
                If you choose to delete, the SAFE will no longer be accessible
                to you and <strong>{safeNote.recipient?.fullName}</strong>. This
                action cannot be undone.
              </Text>
            }
          >
            <Text
              type={TextTypes.BODY_DEFAULT}
              color={Color.neutral500}
              align={TextAlign.CENTER}
              className="tw-mt-8"
            >
              If you choose to delete, the SAFE will no longer be accessible to
              you. This action cannot be undone.
            </Text>
          </ConditionalRender>

          <div className="tw-my-15">
            {CompanyRow}
            {Amount}
            <ConditionalRender
              condition={mfn}
              fallbackElement={
                <>
                  <ConditionalRender condition={!!discount}>
                    {Discount}
                  </ConditionalRender>
                  <ConditionalRender condition={!!valuation}>
                    {Valuation}
                  </ConditionalRender>
                </>
              }
            >
              {MFN}
            </ConditionalRender>
          </div>
        </div>
      </div>

      <TextArea
        className="tw-mt-16"
        placeholder="Send your recipients a note..."
        {...register('message')}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !loading) {
            e.preventDefault()
            onDelete(safeNote.id, getValues('message'))
          }
        }}
      />

      <ModalFooter
        className="tw-mt-30"
        disabled={loading}
        leftButtonText="Cancel"
        leftButtonAction={closeModal}
        rightButtonText={loading ? 'Loading...' : 'Delete'}
        rightButtonActon={() => onDelete(safeNote.id, getValues('message'))}
      />
    </Modal>
  )
}

export default DeleteSafeModal
