import clsx from 'clsx'
import React, { FC } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { Clock } from '@/features/auth/assets/icons'
import { permissions } from '@/features/auth/components/EntrepreneurForm/AddTeamMembers/AddTeamMembers'
import { InviteStatus } from '@/features/auth/enums'
import { SubscriptionsModal } from '@/features/auth/modals'
import { TeamMemberPermission } from '@/features/user'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  FontWeight,
  Row,
  Select,
  Text,
  TextTypes
} from '@/packages/ui'

import styles from './TeamMembersTableRow.module.scss'

interface TeamMembersTableRowProps {
  id: string
  name: string
  minimized?: boolean
  email: string
  avatar: string | null
  role: 'teamMember' | 'owner'
  permission: TeamMemberPermission | 'owner'
  inviteStatus?: InviteStatus
  title?: string
  handleChangePermission: (id: string, permission: TeamMemberPermission) => void
  handleDeleteTeamMember: (id: string) => void
}

const TeamMembersTableRow: FC<TeamMembersTableRowProps> = (props) => {
  const {
    id,
    minimized,
    name,
    email,
    avatar,
    role,
    permission,
    inviteStatus,
    handleChangePermission,
    handleDeleteTeamMember,
    title
  } = props

  const { visible, openModal, closeModal } = useModal(false)

  const pendingTeamMember = inviteStatus === InviteStatus.Pending
  const withoutName = name === '-'
  const permissionsList =
    id === 'owner'
      ? [...permissions(), { value: 'owner', label: 'Owner' }]
      : permissions()

  return (
    <Row
      items="center"
      justify="between"
      gap={20}
      className={clsx(pendingTeamMember && styles.pending)}
    >
      {visible && <SubscriptionsModal closeModal={closeModal} />}

      <Row items="center" gap={10}>
        <Avatar
          size={minimized ? 36 : 48}
          name={withoutName ? email : name}
          image={avatar}
        />
        <Col>
          <ConditionalRender condition={!withoutName}>
            <Row items="center" gap={5}>
              <Text
                type={minimized ? TextTypes.BODY_SMALL : TextTypes.BODY_DEFAULT}
                weight={FontWeight.SEMIBOLD}
                color={Color.neutral500}
              >
                {name}
              </Text>

              <ConditionalRender
                condition={
                  !!title && title !== '-' && title !== 'true' && !minimized
                }
              >
                <Badge size="small" type={BadgeType.WARNING}>
                  {title}
                </Badge>
              </ConditionalRender>
            </Row>
          </ConditionalRender>

          <ConditionalRender condition={!minimized}>
            <Text
              type={TextTypes.BODY_SMALL}
              weight={FontWeight.MEDIUM}
              color={Color.neutral400}
              className="!tw-flex tw-items-center tw-gap-6"
            >
              {email}
            </Text>
          </ConditionalRender>
        </Col>
      </Row>

      <Row items="center" gap={minimized ? 5 : 10}>
        <ConditionalRender
          condition={!pendingTeamMember}
          fallbackElement={
            <Row
              className={clsx(styles.pendingBox, minimized && styles.minimized)}
              items="center"
              gap={minimized ? 5 : 12}
            >
              <Text
                type={TextTypes.BODY_SMALL}
                weight={FontWeight.SEMIBOLD}
                color={Color.neutral500}
              >
                Pending
              </Text>
              <Clock />
            </Row>
          }
        >
          <Select
            small
            custom
            withSearch={false}
            withPortal
            onUpgrade={openModal}
            withoutPadding={minimized}
            name="teamMemberPermission"
            options={permissionsList}
            value={permission}
            disabled={permission === 'owner' || role === 'teamMember'}
            onChange={(val: string) =>
              handleChangePermission(id, val as TeamMemberPermission)
            }
          />
        </ConditionalRender>

        <ConditionalRender
          condition={permission !== 'owner' && role === 'owner'}
        >
          <SharedIcons.Trash
            onClick={() => handleDeleteTeamMember(id)}
            color={Color.neutral400}
            size={minimized ? 20 : 24}
          />
        </ConditionalRender>
      </Row>
    </Row>
  )
}

export default TeamMembersTableRow
