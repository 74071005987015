import cloneDeep from 'lodash/cloneDeep'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { RootStoreType } from '@/common/store'
import StoreHandler from '@/common/store/StoreHandler'
import { InviteStatus } from '@/features/auth/enums'
import {
  ICompany,
  ICompanyTeamMember,
  ISortedCompanies
} from '@/features/profile/types'
import {
  CreateEntrepreneurProfileDto,
  CreateNewProfileDto,
  IUser, TeamMemberPermission,
  UpdateTeamMemberProps,
  UpdateUserDto,
  UpdateUserSubscription
} from '@/features/user/api/types'
import { ProfileTypes } from '@/features/user/enums'

import { UserRequests } from '../api/UserRequests'

class UserStore extends StoreHandler {
  @observable me: IUser | null

  @observable companies: ICompany[] = []

  @observable deletedCompanies: ICompany[] = []

  constructor(rootStore: RootStoreType) {
    super(rootStore)
    makeObservable(this)
  }

  @action
  public fetchMe = this.execute(async () => {
    const me = await UserRequests.fetchMe()
    const myCompanies = await UserRequests.fetchMyCompanies()
    const deletedCompanies = await UserRequests.fetchDeletedCompanies()

    runInAction(() => {
      if (me) {
        this.me = me
      }
      this.companies = myCompanies || []
      this.deletedCompanies = deletedCompanies || []
    })
  }, 'fetchMe')

  @action
  public clearUser = this.execute(async () => {
    this.me = null
    this.companies = []
  }, 'clearUser')

  @action
  public updateGoal = (companyId: string, goal: number) => {
    runInAction(() => {
      this.companies = this.companies.map((company) =>
        company.id === companyId ? { ...company, goal } : company
      )
    })
  }

  @action
  public removeCompanyFromList = (companyId: string) => {
    runInAction(() => {
      this.companies = this.companies.filter(
        (company) => company.id !== companyId
      )
    })
  }

  @action
  public addNewCompany = this.execute<CreateNewProfileDto>(
    async ({ data, options }) => {
      const { userData, ...profileData } = data as CreateEntrepreneurProfileDto
      const { data: profileInfo, image, type, investorType } = profileData

      if (userData?.fullName || userData?.image) {
        await UserRequests.updateCurrentUser(userData)
      }

      const newCompany = await UserRequests.createCompany(
        { ...profileInfo, image, type, investorType },
        profileData.type
      )

      await this.fetchMe()

      options?.onSuccess?.(newCompany?.id)
    },
    {
      loaderName: 'addNewCompany',
      manualOnSuccess: true
    }
  )

  @action
  public removeCompany = this.execute<{ id: string }>(
    async ({ data, options }) => {
      const { id } = data

      await UserRequests.deleteCompany(id)

      options?.onSuccess?.()
    },
    {
      loaderName: 'removeCompany',
      manualOnSuccess: true
    }
  )

  @action
  public restoreCompany = this.execute<{ id: string }>(
    async ({ data, options }) => {
      const { id } = data

      await UserRequests.restoreCompany(id)

      await this.fetchMe()

      options?.onSuccess?.()
    },
    {
      loaderName: 'restoreCompany',
      manualOnSuccess: true
    }
  )

  @action
  public deleteTeamMember = this.execute<{ companyId: string; id: string }>(
    async ({ data, options }) => {
      await UserRequests.deleteTeamMember(data.companyId, data.id)

      runInAction(() => {
        const newCompanies = cloneDeep(this.companies)

        this.companies = newCompanies.map((company: ICompany) =>
          company.id === data.companyId
            ? {
                ...company,
                teamMembers: company.teamMembers.filter(
                  (member: ICompanyTeamMember) => member.userId !== data.id
                )
              }
            : company
        )
      })

      options?.onSuccess?.()
    },
    {
      loaderName: 'deleteTeamMember',
      manualOnSuccess: true
    }
  )

  @action
  public updateCurrentUser = this.execute<{
    payload: UpdateUserDto
  }>(
    async ({ data, options }) => {
      await UserRequests.updateCurrentUser(data.payload)

      await this.fetchMe()

      options?.onSuccess?.()
    },
    {
      loaderName: 'updateUser',
      manualOnSuccess: true
    }
  )

  @action
  public updateUserSubscription = this.execute<{
    payload: UpdateUserSubscription
  }>(
    async ({ data }) => {
      await UserRequests.updateUserSubscription(data.payload)

      await this.fetchMe()
    },
    {
      loaderName: 'updateUserSubscription'
    }
  )

  @action
  public updateTeamMember = this.execute<{
    companyId: string
    teamMemberId: string
    values: UpdateTeamMemberProps
  }>(
    async ({ data: { companyId, teamMemberId, values } }) => {
      await UserRequests.updateTeamMember(companyId, teamMemberId, values)

      runInAction(() => {
        const newCompanies = cloneDeep(this.companies)

        this.companies = newCompanies.map((company: ICompany) =>
          company.id === companyId
            ? {
                ...company,
                teamMembers: company.teamMembers.map(
                  (member: ICompanyTeamMember) =>
                    member.userId === teamMemberId
                      ? { ...member, ...values }
                      : member
                )
              }
            : company
        )
      })
    },
    {
      loaderName: 'updateTeamMember'
    }
  )

  @action
  public getPublicUserByEmail = this.execute<{ email: string }>(
    ({ data }) => UserRequests.getPublicUserByEmail(data.email),
    'getPublicUserByEmail'
  )

  @action
  public resendVerifyEmail = this.execute(
    async ({ options }) => {
      await UserRequests.resendVerifyEmail()

      options?.onSuccess?.()
    },
    { manualOnSuccess: true, loaderName: 'resendVerifyEmail' }
  )

  @action
  public refetchCompany = this.execute<{ id: string }>(async ({ data }) => {
    const newCompany = await UserRequests.fetchCompany(data.id)

    if (!newCompany) return

    runInAction(() => {
      this.companies = this.companies.map((company) =>
        company.id === data.id ? newCompany : company
      )
    })
  }, 'refetchCompany')


  @computed
  public get companiesPermission(): {[key: string]: TeamMemberPermission} {
    const permissions: { [key: string]: TeamMemberPermission } = {}

    this.companies.forEach((company) => {
      if(company?.companyUser?.permission) {
        permissions[company.id] = company.companyUser.permission
      } else if(company?.companyUser.role === 'owner') {
        permissions[company.id] = TeamMemberPermission.CREATE
      } else {
        permissions[company.id] = TeamMemberPermission.VIEW
      }

    })

    return permissions
  }

  @computed
  public get sortedCompanies(): ISortedCompanies {
    const result: ISortedCompanies = {
      angel: [],
      entrepreneur: [],
      requests: [],
      advisor: [],

      deleted: {
        angel: [],
        entrepreneur: [],
        advisor: []
      }
    }

    const myId = this.me?.id

    if (!!this.companies && this.companies.length) {
      this.companies.forEach((item: ICompany) => {
        const meAsTeamMember = item.teamMembers.find(
          (member: ICompanyTeamMember) => member.userId === myId
        )

        if (
          item.owner.id !== myId &&
          meAsTeamMember?.inviteStatus === InviteStatus.Pending
        ) {
          result.requests.push(item)
          return
        }

        if (item.type === ProfileTypes.ANGEL) {
          result.angel.push(item)
        }

        if (item.type === ProfileTypes.ENTREPRENEUR) {
          result.entrepreneur.push(item)
        }
      })
    }

    if (!!this.deletedCompanies && this.deletedCompanies.length) {
      this.deletedCompanies.forEach((item: ICompany) => {
        const meAsTeamMember = item.teamMembers.find(
          (member: ICompanyTeamMember) => member.userId === myId
        )

        if (
          item.owner.id !== myId &&
          meAsTeamMember?.inviteStatus !== InviteStatus.Accepted
        ) {
          return
        }

        if (item.type === ProfileTypes.ANGEL) {
          result.deleted.angel.push(item)
        }

        if (item.type === ProfileTypes.ENTREPRENEUR) {
          result.deleted.entrepreneur.push(item)
        }
      })
    }

    return result
  }
}

export default UserStore
