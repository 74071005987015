import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'

import { ModalFooter } from '@/common/components'
import verifyEmailIcon from '@/features/dashboard/assets/images/verify-email.png'
import { EditDraftRecipientSchema } from '@/features/safe-note/schemas'
import { IUser } from '@/features/user'
import { Color } from '@/packages/palette'
import {
  Col,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Row,
  Text,
  TextAlign,
  TextInput,
  TextTypes
} from '@/packages/ui'

import styles from './EditDraftRecipientModal.module.scss'

const MODAL_ID = 'edit-draft-recipient-modal'

interface EditDraftRecipientModalProps extends IModalWithCloseFn {
  onSave: ({ email, name }: { email: string; name: string }) => void
  recipient: IUser
  companyId?: string
  loading: boolean
}

const EditDraftRecipientModal: FC<EditDraftRecipientModalProps> = ({
  closeModal,
  onSave,
  loading,
  companyId,
  recipient
}) => {
  const {
    register,
    watch,
    formState: { errors, isValid, isDirty }
  } = useForm({
    resolver: yupResolver(EditDraftRecipientSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      recipientName: recipient.fullName,
      recipientEmail: recipient.email
    },
    context: {
      companyId
    }
  })

  const handleClick = () => {
    if (!isValid) return

    onSave({
      name: watch('recipientName'),
      email: watch('recipientEmail')
    })
  }

  const disabledButtonCondition = !isValid || loading || !isDirty

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <Col items="center" gap={20} className={styles.container}>
        <img src={verifyEmailIcon} alt="Verify email" />

        <Col items="center" gap={16}>
          <Heading type={HeadingTypes.H3} align={TextAlign.CENTER}>
            Edit SAFE recipient
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral500}
            align={TextAlign.CENTER}
          >
            After editing the recipient, a SAFE invitation will be sent to the
            new user.
          </Text>
        </Col>

        <Form schema={EditDraftRecipientSchema}>
          <Row gap={10}>
            <FormItem errors={errors.recipientEmail?.message}>
              <TextInput
                {...register('recipientEmail')}
                autoFocus
                label="Investor email"
                placeholder="Email"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !disabledButtonCondition) {
                    handleClick()
                  }
                }}
                invalid={FormHelper.isFieldInvalid('recipientEmail', errors)}
              />
            </FormItem>

            <FormItem errors={errors.recipientName?.message}>
              <TextInput
                {...register('recipientName')}
                label="Investor name"
                placeholder="Name"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !disabledButtonCondition) {
                    handleClick()
                  }
                }}
                invalid={FormHelper.isFieldInvalid('recipientName', errors)}
              />
            </FormItem>
          </Row>
        </Form>
      </Col>

      <ModalFooter
        className="tw-mt-28"
        leftButtonText="Cancel"
        rightButtonText={loading ? 'Loading...' : 'Save'}
        disabled={disabledButtonCondition}
        rightButtonActon={handleClick}
        leftButtonAction={closeModal}
      />
    </Modal>
  )
}

export default EditDraftRecipientModal
