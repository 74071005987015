import * as yup from 'yup'

import { Regex } from '@/common/regex'
import { store } from '@/common/store'

export const RecipientStepSchema = yup
  .object({
    recipientName: yup.string().required('Name is required'),
    recipientEmail: yup
      .string()
      .matches(Regex.Email, 'Invalid email')
      .matches(Regex.Email)
      .required('Email is required')
      .test('not-me', 'You cannot create SAFE for yourself', async (value) => {
        const { me } = store.user

        if(!me) {
          await store.user?.fetchMe()
        }

        return value !== me?.email
      }).test('not-owner', 'You cannot create SAFE for owner of your company', async function (value) {
        const { companies } = store.user

        const companyId: string | undefined = this.options?.context?.companyId

        if(!companyId) return true

        const currentCompany = companies?.find((company) => company.id === companyId)

        if(!currentCompany) return true

        return currentCompany.owner.email !== value

      }),
    safeAmount: yup
      .number()
      .required('SAFE amount is required')
      .min(1, 'Amount should be in the format of 00.00')
  })
  .required()

export const EditDraftRecipientSchema = yup
  .object({
    recipientName: yup.string().required('Name is required'),
    recipientEmail: yup
      .string()
      .matches(Regex.Email, 'Invalid email')
      .required('Email is required')
      .test('not-me', 'You cannot create SAFE for yourself', async (value) => {
        const { me } = store.user

        if(!me) {
          await store.user?.fetchMe()
        }

        return value !== me?.email
      }).test('not-owner', 'You cannot create SAFE for owner of your company', async function (value) {
        const { companies } = store.user

        const companyId: string | undefined = this.options?.context?.companyId

        if(!companyId) return true

        const currentCompany = companies?.find((company) => company.id === companyId)

        if(!currentCompany) return true

        return currentCompany.owner.email !== value

      })
  })
  .required()
